@import "../../style/hornet_colors";

.pickerBox {
	background: $hornet-white;
	border: 1px solid $hornet-gray;
	border-radius: 5px;
}

.fontRow,
.fontRowSelected {
	cursor: pointer;
	transition: all 0.3s ease;
}

.fontRowSelected {
	background: $hornet-blue;
	color: #fff;
}


.fontCell {
	padding: 0 10px !important;
	height: 40px;
	line-height: 40px;
}