@import "../../style/hornet_colors";

$btn-size: 26px;

.btnBox{
  position: fixed;
  top:10px;
  right:10px;
  font-size: $btn-size;
  
  .btn{
	margin-top: 8px;
	width: $btn-size;
	height: $btn-size;
	font-size: 0.6em;
	line-height: $btn-size - 2;
	background: #fff;
	color: $hornet-gray;
	border-radius: $btn-size / 2;
	box-shadow: 0 2px 10px rgba(0,0,0,0.2);
	cursor: pointer;
	border: 1px solid #fff;
	transition: all 0.2s ease-in-out;
	&:hover{
	  transform: scale(1.1);
	}
	&.disabled {
	  opacity: 0.5;
	  cursor: default;

	  &:hover {
		transform: none;
	  }
	}
  }
}