@import "../style/hornet_colors";

.box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.iconBox {
	color: $hornet-blue;
	text-align: center;
	font-size: 30px;
}

.textBox {
	text-align: center;
	text-transform: uppercase;
}
