@import "../style/hornet_colors";
@import "../style/bootstrap_vars";


.dlBox {
	background: $hornet-offwhite;
	padding: 20px;
	border: 1px solid $hornet-light-gray;
	border-radius: 5px;
	display: block;
	max-width: 400px;
	margin: 20px auto;
	position: relative;
}

.left {
	font-size: 150px;
	line-height: 150px;
	text-align: center;
}

.right {
	position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
	width: calc(100% - 30px);
}

.docComplete {
	text-align: center;
	font-size: 24px;
	color: $green;
	font-weight: 600;
}

.docNotComplete {
	text-align: center;
	font-size: 24px;
	color: $yellow;
	font-weight: 600;
}