@import "../../style/hornet_colors";

.controlBtn {
	cursor: pointer;
	display: inline-block;
}

.signatureBox {
	position: absolute;
	background: rgb(255, 240, 104);
	color: rgb(199, 73, 0);
	padding: 0 10px;
	text-transform: uppercase;
	font-weight: 800;
	box-shadow: 5px 2px 5px rgba(0, 0, 0, 0.2);
	font-size: 12px;
	cursor: pointer;
}

.signatureImage {
  position: absolute;
  img {
	width: 100%;
	height: 100%;
	//image-rendering: pixelated;
	
  }
}

.sigIcon {
	//font-size: 20px;
  	font-size: 1.7em;
	position: absolute;
	left: 5px;
    //left: 0.3em;
	top: 0;
}

.sigText {
	//margin-left: 15px;
	margin-left: 1.3em;
}

.pageBox {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	background: #fff;
  	margin-bottom: 20px;
  	[class~=svg-doc],
	[class~=svg-page],
	[class~=react-pdf__Page__svg],
	svg {
	  		width: 100% !important;
	  		height: 100% !important;
	}

}

.pageNumberBox {
	font-size: 14px;
	margin: 10px 0 20px;
}

$bottom-bar-height: 55px;
.bottomBar {
	height: $bottom-bar-height;
	background: $hornet-blue;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	color: #fff;
	line-height: $bottom-bar-height;
}
.bottomSpacer {
	height: $bottom-bar-height;
}

.loadingBg {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: transparentize($hornet-blue, 0.4);
	opacity: 0;
	transition: all 0.3s ease;
	pointer-events: none;
}

.loadingBgActive {
	pointer-events: all;
	opacity: 1;
}

.loadingBox {
	color: #fff;
	font-size: 40px;
	position: absolute;
	top:50%;
	left:50%;
	text-align: center;
}

.loadingText {
	font-size: 18px;
}
