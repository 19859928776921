@import "./style/hornet_colors";
@import "./style/bootstrap_vars";
@import "~bootstrap/scss/bootstrap";

h3 {
	font-weight: 400;
	border-bottom: 3px solid $hornet-blue;
	margin-bottom: 20px;
}

.spin {
	-webkit-animation: icon-spin 2s infinite linear;
	animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
